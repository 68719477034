import React from 'react';
import {useTranslation} from 'react-i18next';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import {withLocaleProvider} from '../../locale/LocaleProvider';

export const LanguageTestsPage = () => {
  const {t, i18n} = useTranslation();
  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('Language-tests:seo:title')}
        description={t('Language-tests:seo:description')}
        url={t('Language-tests:seo:url')}
      />
      <Layout beforeFooter>
        <div className="breadcrumbs-v3 img-v2">
          <div className="container text-center">
            <p>{t('Language-tests:intro:subtitle')}</p>
            <h1>{t('Language-tests:intro:title')}</h1>
          </div>
        </div>
        <div className="container content-sm">
          <div className="lead text-center margin-bottom-40">
            <p
              dangerouslySetInnerHTML={{
                __html: t('Language-tests:contents:text-1'),
              }}
            />
          </div>
          <ul className="margin-bottom-40">
            <li>{t('Language-tests:contents:list-1:item-1')}</li>
            <li>{t('Language-tests:contents:list-1:item-2')}</li>
            <li>{t('Language-tests:contents:list-1:item-3')}</li>
          </ul>
          <hr />
          <div className="lead text-center margin-bottom-40">
            <p
              dangerouslySetInnerHTML={{
                __html: t('Language-tests:contents:text-2'),
              }}
            />
          </div>
          <ul className="margin-bottom-34">
            <li>{t('Language-tests:contents:list-2:item-1')}</li>
            <li>{t('Language-tests:contents:list-2:item-2')}</li>
          </ul>
          <hr />
          <p>{t('Language-tests:contents:text-3')}</p>
        </div>
        <div
          className="parallax-counter-v2 parallaxBg1"
          style={{backgroundPosition: '50% 51px'}}
        >
          <div className="container">
            <ul className="row list-row">
              <li className="col-sm-6 col-xs-12 sm-margin-bottom-30">
                <div className="counters rounded language-courses">
                  <a href={t('Language-tests:contents:choice-1:link-url')}>
                    <h4 className="font-normal">
                      {t('Language-tests:contents:choice-1:text')}
                    </h4>
                  </a>
                </div>
              </li>
              <li className="col-sm-6 col-xs-12">
                <div className="counters rounded language-courses">
                  <a href={t('Language-tests:contents:choice-2:link-url')}>
                    <h4 className="font-normal">
                      {t('Language-tests:contents:choice-2:text')}
                    </h4>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="container content-sm">
          <div className="heading heading-v5 margin-bottom-40">
            <h2>
              <strong>{t('Language-tests:contents:title')}</strong>
            </h2>
          </div>
          <div className="col-md-6">
            <h3>{t('Language-tests:contents:list-3:title')}</h3>
            <ul>
              <li>
                <strong>{t('Language-tests:contents:list-3:item-1')}</strong>
              </li>
              <li>
                <strong>{t('Language-tests:contents:list-3:item-2')}</strong>
              </li>
              <li>
                <strong>{t('Language-tests:contents:list-3:item-3')}</strong>
              </li>
              <li>
                <strong>{t('Language-tests:contents:list-3:item-4')}</strong>
              </li>
              <li>
                <strong>{t('Language-tests:contents:list-3:item-5')}</strong>
              </li>
            </ul>
            <p>{t('Language-tests:contents:list-3:text')}</p>
          </div>
          <div className="col-md-6">
            <h3>{t('Language-tests:contents:list-4:title')}</h3>
            <ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: t('Language-tests:contents:list-4:item-1'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('Language-tests:contents:list-4:item-2'),
                }}
              />
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', LanguageTestsPage);
